import React from "react";
import "./RecipeCard.scss";

const RecipeCard = ({
  Link,
  recipeLargeImage,
  recipeImageAlt,
  RecipeName,
  Category,
}) => {
  return (
    <a href={"/" + Link} className="RecipeCard">
      <div className="RecipeCard__Image">
        <img loading="lazy" src={recipeLargeImage} alt={recipeImageAlt} />
      </div>
      <div className="RecipeCard__Text">
        <h3>{RecipeName}</h3>
      </div>
    </a>
  );
};

export default RecipeCard;
