import React, { useState, useEffect } from "react";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { chickenRecipes } from "../../data/chickenRecipes";
import { hamRecipes } from "../../data/hamRecipes";
import { turkeyRecipes } from "../../data/turkeyRecipes";
import "./RecipeFilter.scss";
import RecipeGrid from "../RecipeGrid/RecipeGrid";
import _ from "lodash";

const RecipeFilter = (props) => {
  const allData = [...chickenRecipes, ...hamRecipes, ...turkeyRecipes];
  const [selectedProtein, setSelectedProtein] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  let _cards = allData;

  const [cards, setCards] = useState(_cards);

  const filterProteinValueChanged = (selectedValue) => {
    setSelectedProtein(selectedValue);
  };

  const filterCategoryValueChanged = (selectedValue) => {
    setSelectedCategory(selectedValue);
  };

  let filterCardsProtein = (pSelected) => {
    if (pSelected === "All" || pSelected === "") {
      return allData;
    } else {
      return _.filter(allData, (obj) => {
        return _.includes(obj.filterProtein, pSelected);
      });
    }
  };

  const filterCardsCategory = (protSelected, catSelected) => {
    const fData = filterCardsProtein(protSelected);
    if (catSelected === "All" || catSelected === "") {
      return fData;
    } else {
      return _.filter(fData, (obj) => {
        return _.includes(obj.filterCategory, catSelected);
      });
    }
  };

  useEffect(() => {
    printCards(filterCardsProtein(selectedProtein));
    printCards(filterCardsCategory(selectedProtein, selectedCategory));
  }, [selectedProtein]);

  useEffect(() => {
    printCards(filterCardsCategory(selectedProtein, selectedCategory));
  }, [selectedCategory]);

  useEffect(() => {
  }, [cards]);

  const printCards = (data) => {
    setCards(data);
    _cards = data;
  };

  return (
    <div className="RecipeFilter">
      <h2 className="RecipeFilter__Text">PROTEINS</h2>
      <div className="RecipeFilter__Container">
        <ToggleButtonGroup
          className="RecipeFilter__Container__Protein"
          type="radio"
          name="proteinFilter"
          // onChange={filterProteinValueChanged}
          //defaultValue={"All"}
        >
          <ToggleButton
            variant="primary"
            className="RecipeFilter__Container__Protein__Button"
            onClick={() => filterProteinValueChanged("turkey")}
            value={"turkey"}
            id="tbg-p-radio-1"
          >
            TURKEY
          </ToggleButton>
          <ToggleButton
            className="RecipeFilter__Container__Protein__Button"
            onClick={() => filterProteinValueChanged("ham")}
            value={"ham"}
            id="tbg-p-radio-2"
          >
            HAM
          </ToggleButton>
          <ToggleButton
            className="RecipeFilter__Container__Protein__Button"
            onClick={() => filterProteinValueChanged("chicken")}
            value={"chicken"}
            id="tbg-p-radio-3"
          >
            CHICKEN
          </ToggleButton>
          <ToggleButton
            className="RecipeFilter__Container__Protein__Button"
            onClick={() => filterProteinValueChanged("All")}
            value={"All"}
            id="tbg-p-radio-0"
          >
            SHOW ALL
          </ToggleButton>
        </ToggleButtonGroup>

        {/* category filter */}
        <ToggleButtonGroup
          className="RecipeFilter__Container__Category"
          type="radio"
          name="categoryFilter"
          // onChange={filterCategoryValueChanged}
          //defaultValue={"All"}
        >
          <ToggleButton
            className="RecipeFilter__Container__Category__Button"
            onClick={() => {
              filterCategoryValueChanged("break");
            }}
            value={"break"}
            id="tbg-c-radio-1"
          >
            Breakfast
          </ToggleButton>
          <ToggleButton
            className="RecipeFilter__Container__Category__Button"
            onClick={() => {
              filterCategoryValueChanged("lunch");
            }}
            value={"lunch"}
            id="tbg-c-radio-2"
          >
            Lunch
          </ToggleButton>
          <ToggleButton
            className="RecipeFilter__Container__Category__Button"
            onClick={() => {
              filterCategoryValueChanged("snacks");
            }}
            value={"snacks"}
            id="tbg-c-radio-3"
          >
            Snacks
          </ToggleButton>
          <ToggleButton
            className="RecipeFilter__Container__Category__Button"
            onClick={() => {
              filterCategoryValueChanged("game");
            }}
            value={"game"}
            id="tbg-c-radio-4"
          >
            Game Day
          </ToggleButton>
          <ToggleButton
            className="RecipeFilter__Container__Category__Button"
            onClick={() => {
              filterCategoryValueChanged("enter");
            }}
            value={"enter"}
            id="tbg-c-radio-5"
          >
            Entertaining
          </ToggleButton>
          <ToggleButton
            className="RecipeFilter__Container__Category__Button"
            onClick={() => {
              filterCategoryValueChanged("picnic");
            }}
            value={"picnic"}
            id="tbg-c-radio-6"
          >
            Picnics
          </ToggleButton>
          <ToggleButton
            className="RecipeFilter__Container__Category__Button"
            onClick={() => {
              filterCategoryValueChanged("grill");
            }}
            value={"grill"}
            id="tbg-c-radio-7"
          >
            Grilling
          </ToggleButton>
          <ToggleButton
            className="RecipeFilter__Container__Category__Button"
            onClick={() => {
              filterCategoryValueChanged("All");
            }}
            value={"All"}
            id="tbg-c-radio-0"
          >
            All
          </ToggleButton>
        </ToggleButtonGroup>
        <select
          id="filterProteins"
          className="Mobile"
          // value={_selectedProtein}
          //defaultValue="All"
          onChange={(option) =>
            filterProteinValueChanged(option.currentTarget.value)
          }
        >
          <option value="All">-Select Protein-</option>
          <option value="turkey">TURKEY</option>
          <option value="ham">HAM</option>
          <option value="chicken">CHICKEN</option>
          <option value="All">SHOW ALL</option>
        </select>
        <select
          id="filterCategory"
          className="Mobile"
          // value={_selectedCategory}
          //defaultValue="All"
          onChange={(option) =>
            filterCategoryValueChanged(option.currentTarget.value)
          }
        >
          <option value="All">-Select Category-</option>
          <option value="break">BREAKFAST</option>
          <option value="lunch">LUNCH</option>
          <option value="snacks">SNACKS</option>
          <option value="game">GAME DAY</option>
          <option value="enter">ENTERTAINING</option>
          <option value="picnic">PICNICS</option>
          <option value="grill">GRILLING</option>
          <option value="All">SHOW ALL</option>
        </select>
      </div>
      <RecipeGrid filteredCards={cards} />
    </div>
  );
};

export default RecipeFilter;
