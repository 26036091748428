import React from "react";
import { Row } from "react-bootstrap";
import HeroImage from "../components/HeroImage/HeroImage";
import Layout from "../components/Layout";
import "../scss/recipes.scss";
import RecipeFilter from "../components/RecipeFilter/RecipeFilter";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";

const Recipes = () => {
  const backImages = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativePath: {
            in: [
              "recipes/RECIPE-LANDING-2400.jpg"
              "recipes/FEATURED-RECIPE-kebabs-1.jpg"
            ]
          }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                blurredOptions: { toFormat: WEBP }
                formats: WEBP
                layout: FULL_WIDTH
                placeholder: BLURRED
                quality: 100
                webpOptions: { quality: 100 }
              )
            }
          }
        }
      }
    }
  `);
  const heroFeaturedRecipe = getImage(backImages.allFile.edges[0].node);
  const heroRecipe = getImage(backImages.allFile.edges[1].node);

  const heroRecipeBG = convertToBgImage(heroRecipe);
  const heroFeaturedRecipeBG = convertToBgImage(heroFeaturedRecipe);
  return (
    <Layout title="Recipes" description="">
      <main>
        <BackgroundImage
          Tags="section"
          preserveStackingContext
          {...heroRecipeBG}
          className="heroRecipe"
          alt="Recipes Banner"
        >
          <Row className="row">
            <HeroImage title={"RECIPES"} isMainTitle={true}></HeroImage>
          </Row>
        </BackgroundImage>
        <figure className="no-desktop">
          <StaticImage
            loading="lazy"
            src="../images/recipes/FEATURED-RECIPE-kebabs-1.jpg"
            alt="Featured Recipe Kebabs"
            width={500}
            height={129}
          />
        </figure>
        <BackgroundImage
          Tags="section"
          preserveStackingContext
          {...heroFeaturedRecipeBG}
          className="heroFeaturedRecipe"
          alt="Featured Recipe Kebabs"
        >
          <Row className="row">
            <HeroImage
              title={"FEATURED RECIPE"}
              subtitle={
                "Make your next barbecue pop with a platter of sweet and tangy meat kebabs! Glaze thick chunks of perfectly seasoned Hickory Smoked Ham with maple syrup for a bold, gluten free treat."
              }
              subtitleClassname={"white"}
              text={"GET RECIPE"}
              link={"/recipes/grilled-hickory-smoked-ham-kebabs/"}
            ></HeroImage>
          </Row>
        </BackgroundImage>

        <section className="">
          <RecipeFilter />
        </section>
      </main>
    </Layout>
  );
};

export default Recipes;
